import ReactDOM from "react-dom/client";
import "./index.css";
import { createHashRouter, RouterProvider } from "react-router-dom";
import Layout from "./pages/layout/Layout.tsx";
import HomePage from "./pages/home_page/HomePage.tsx";
// import UserLogin from "./pages/login/UserLogin.tsx";
import Thoughts from "./pages/thoughts/Thoughts.tsx";
import ThoughtList from "./thought_list/ThoughtList.tsx";
import NoPage from "./pages/NoPage.tsx";
import Privacy from "./pages/privacy/Privacy.tsx";



const router = createHashRouter([
  // Root route for both Google login and UUID-based navigation
  {
    path: "/",
    element: <Layout />,
    children: [
      // UUID-based navigation route
      {
        path: "/:uuid",
        // element: <Layout />,
        children: [
          {
            index: true,
            element: <HomePage />,
          },
          {
            path: "workbench",
            element: <HomePage />,
          },
          {
            path: "thoughts/:thought_id",
            element: <Thoughts prompt={""} response={""} citations={""} />,
          },
          {
            path: "thoughts",
            element: <ThoughtList />,
          },
          {
            path: "privacy",
            element: <Privacy/>,
          },
          {
            path: "*",
            element: <NoPage />,
          },
        ],
      }
    ],

  },

]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <RouterProvider router={router} />
);
