import { get_mentee_data } from "../utils/common";
import { BaseURL, Chat, Seed, caselet } from "../utils/appConstant";

// function getHeaders(idToken: string | undefined): Record<string, string> {
//     let headers: Record<string, string> = {
//         "Content-Type": "application/json"
//     };

//     return headers;
// }

// export async function makeAPIRequest(request: AppRequest, token: string | undefined): Promise<Response> {
//     return await fetch(`${BACKEND_URI}/seeds`, {
//         method: "POST",
//         headers: getHeaders(token),
//         body: JSON.stringify(request)
//     });
// }
export async function getRequest(): Promise<Response> {
    return await fetch("https://reqres.in/api/users", {
        method: "GET",
        headers: {
            Accept: "application/json",
            // 'Authorization': `Bearer ${token}`
        }
    });
}
export async function getFiles(): Promise<Response> {
    const userId =  get_mentee_data().email;
    // console.log("mentee_ data: ",  mentee)
    return await fetch(`${BaseURL}/${Chat}/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            // 'Authorization': `Bearer ${token}`            
        },
        credentials:'include'
    });
}


export async function getFileChats(fileName: string | undefined): Promise<Response> {
    const userId =  get_mentee_data().email;
    return await fetch(`${BaseURL}/${Chat}/${userId}/${fileName}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            // 'Authorization': `Bearer ${token}`
           
        },
        credentials:'include'
        
    });
}
export async function generateChat (fileName: string | undefined, prompt: string): Promise<Response> {
    const userId =  get_mentee_data().email;
    const userName = get_mentee_data().name
    const mentee_uuid = localStorage.getItem("mentee_uuid")
    // console.log("mentee name : ",userName )
    return await fetch(`${BaseURL}/${Chat}/${userId}/${fileName}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
            // 'Authorization': `Bearer ${token}`

            
        },
        credentials:'include',
        body: JSON.stringify({
            query: prompt,
            userName: userName,
            mentee_uuid:mentee_uuid
        })
    });
}


export async function getSeedFileInfo(fileName: string | undefined): Promise<Response> {
    return await fetch(`${BaseURL}/${Seed}/${fileName}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            // 'Authorization': `Bearer ${token}`
           
        },
        credentials:'include'
    });
}

export async function getPublishedCaseletsFiles(): Promise<Response> {
    return await fetch(`${BaseURL}/${caselet}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            // 'Authorization': `Bearer ${token}`
           

        },
        credentials:'include'
    });
}

export async function getPublishedCaseletFileInfo(fileName: string | undefined): Promise<Response> {
    // //console.log("file name: ", fileName)
    return await fetch(`${BaseURL}/${caselet}/${fileName}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            // 'Authorization': `Bearer ${token}`
           
        },
        credentials:'include'
    });
}



export async function getId(): Promise<Response> {
    return await fetch(`${BaseURL}/generateId`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            // 'Authorization': `Bearer ${token}`
        },
        credentials:'include'
    });
}

export async function checkFile(id:string | undefined): Promise<Response> {
    const userId =  get_mentee_data().email;
    return await fetch(`${BaseURL}/checkfile/${userId}/${id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            // 'Authorization': `Bearer ${token}`
            
        },
        credentials:'include'
    })
}

export async function checkUser(): Promise<Response> {
    const userId =  get_mentee_data().email;

    // console.log("userId: ",userId  )
    return await fetch(`${BaseURL}/check_user/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            // 'Authorization': `Bearer ${token}`
        },
        credentials: "include" // This is important for sending cookies
    });
}
//this api call when user do semantic search in caselet list
export async function searchCaseletAPI(searchText: string): Promise<Response> {
    return await fetch(`${BaseURL}/searchCaselet`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
            // 'Authorization': `Bearer ${token}`
        },
        credentials: "include",
        body: JSON.stringify({
            searchText: searchText
        })
    });
}

export async function caseletEngagement(caselet_id: string, mentor_name: string, mentee_name: string): Promise<Response> {
    // const token = localStorage.getItem('mentee_access_token')
    return await fetch(`${BaseURL}/caseletEngagement`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
            // 'Authorization': `Bearer ${token}`
        },
        credentials: "include",
        body: JSON.stringify({
            caselet_id: caselet_id,
            mentor_name: mentor_name,
            mentee_name: mentee_name
        })
    });

}

export async function ChatFeedBack(fileName: string | undefined, chatFeedBack: string, chatIndexValue?: number): Promise<Response> {
    const userId =  get_mentee_data().email;
    const mentee_uuid = localStorage.getItem("mentee_uuid")

    return await fetch(`${BaseURL}/${Chat}/feedback`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
            // 'Authorization': `Bearer ${token}      
        },
        credentials:'include',
        body: JSON.stringify({
            user_id: userId,
            file_name: fileName,
            feed_back: chatFeedBack,
            index_value: chatIndexValue,
            mentee_uuid:mentee_uuid
        })
    });
}