import { useEffect, useState } from "react";
import { Card, CardBody, Tooltip } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { PlusIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import "./HomePage.module.css";
import {

  getFileChats,
  getFiles,
  getId,
  checkUser,
} from "../../api";
import { CardSkeleton } from "../../utils/CardSkeleton";

type Thought = {
  filename: string;
  timestamp: string;
  query: string;
  response: string;
  citations: string[];
};

function giveErrorMessage(statusCode: number | undefined) {
  console.log("giveErrorMessage : ", statusCode);
  switch (statusCode) {
    case 404:
      return (
        <Card className="pb-4 w-full h-44 card_bg_color card_text_color">
          <CardBody className="p-2 flex  justify-center  font-bold">
            Resource not found.
          </CardBody>
        </Card>
      );
    case 500:
      return (
        <Card className="pb-4 w-full h-44 card_bg_color card_text_color">
          <CardBody className="p-2 flex  justify-center  font-bold">
            Internal server error. Please try again later.
          </CardBody>
        </Card>
      );
    default:
      return (
        <Card className="  pb-4 w-full h-44 card_bg_color card_text_color">
          <CardBody className="p-2 flex  justify-center  font-bold">
            Oops, something went wrong!
          </CardBody>
        </Card>
      );
  }
}

const ThoughtProcess = () => {
  const [thoughts, setThoughts] = useState<Thought[]>([]);
  const [error, setError] = useState(false);
  const [statusCode, setStatusCode] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [checkThoughtsFolderNotExist, setCheckThoughtsFolderNotExist] =
  useState(false);
  const navigate = useNavigate();
 

  const handleSeeMoreClick = () => {
    navigate(`thoughts`, { state: { thoughts } });
  };

  useEffect(() => {
    async function fetchThoughts() {
      try {
        const check_user_dir = await checkUser();
        if (check_user_dir.status === 401 || check_user_dir.status === 422) {
          document.cookie = `mentee_token_cookie=''; path=/; SameSite=None; Secure`;
          localStorage.removeItem("mentee_data");
        
        } else if (!check_user_dir.ok) {
          throw new Error(`Error! status: ${check_user_dir.status}`);
        }
        const checkUserDirExist = await check_user_dir.json();
        if (checkUserDirExist.exists) {
          const thoughtsFilesResponse = await getFiles();
          const filesData = await thoughtsFilesResponse.json();

          if (filesData.error) {
            setCheckThoughtsFolderNotExist(true);
            return;
          }

          if (!thoughtsFilesResponse.ok) {
            setStatusCode(thoughtsFilesResponse.status);
            throw new Error(`Error! status: ${thoughtsFilesResponse.status}`);
          }
          filesData.sort((a: any, b: any) => {
            const timestampA = Number(a.slice(0, -4)); // Remove '.txt' and convert to number
            const timestampB = Number(b.slice(0, -4)); // Remove '.txt' and convert to number
            return timestampB - timestampA; // Sort in descending order (most recent first)
          });

          const fetchedThoughts: Thought[] = [];
          let numberOfFileNeedToLoad = 3;
          for (
            let i = 0;
            i < Math.min(filesData.length, numberOfFileNeedToLoad);
            i++
          ) {
            try {
              const historyResponse = await getFileChats(
                filesData[i].substring(0, filesData[i].lastIndexOf("."))
              );
              if (!historyResponse.ok) {
                throw new Error(`Error! status: ${historyResponse.status}`);
              }
              const historyData = await historyResponse.json();
              const latestThought = historyData[historyData.length - 1];

              latestThought["filename"] = filesData[i].substring(
                0,
                filesData[i].lastIndexOf(".")
              );
              fetchedThoughts.push(latestThought);
            } catch (error) {
              numberOfFileNeedToLoad++;
              console.error(`Failed to fetch thought data `, error);
            }
          }

          setThoughts(fetchedThoughts);
        } 
        setIsLoading(false);
      } catch (error) {
        setError(true);
        console.error(`Failed to fetch thought files `, error);
      }
    }

    fetchThoughts();
  }, []);

  const openSpecificThoughtClick = (thought: Thought) => {
      navigate(`thoughts/${thought.filename}`, {
        state: { from: "individual" },
      });

  };
  const fetchIdAndNavigate = async () => {
    try {
      const idResponse = await getId();
      if (idResponse.status === 401 || idResponse.status === 422) {
        document.cookie = `mentee_token_cookie=''; path=/; SameSite=None; Secure`;
      }
      if (!idResponse.ok) {
        throw new Error(`Failed to fetch ID: ${idResponse.status}`);
      }
      const id = await idResponse.json();
      navigate(`thoughts/${id.Id}`, { state: { from: "new" } });
    } catch (error) {
      console.error("Failed to fetch ID and navigate", error);
    }
  };
  const clickToCreateNewThought = () => {
    fetchIdAndNavigate();
  };

  const truncate = (str: string, maxLength: number) => {
    if (str.length <= maxLength) {
      return str;
    }

    let truncatedString = str.substring(0, maxLength);
    let lastSpaceIndex = truncatedString.lastIndexOf(" ");

    if (lastSpaceIndex === -1) {
      return truncatedString;
    }

    return truncatedString.substring(0, lastSpaceIndex) + " ...";
  };
  if (checkThoughtsFolderNotExist) {
    return (
      <>
        <div className="grid grid-cols-3 gap-3 w-full min-w-96">
          <Card
            onClick={clickToCreateNewThought}
            className="hover:cursor-pointer pb-4 h-44 min-w-32 card_bg_color"
          >
            <CardBody className="p-2 h-screen flex items-center justify-center">
              <PlusIcon className="h-20 w-20 text-sky-400 " />
            </CardBody>
          </Card>
        </div>
        <div className=" flex items-center justify-center  card_bg_color ">
          <div className="h-12 w-12"></div>
        </div>
      </>
    );
  }
  if (isLoading && error === false) {
    return (
      <div className="w-[89vw]">
        <CardSkeleton></CardSkeleton>
      </div>
    );
  }

  if (error) {
    return giveErrorMessage(statusCode);
  }
  return (
    <>
      <div className="grid grid-cols-3 gap-3 w-full min-w-96">
        <Card
          onClick={clickToCreateNewThought}
          className="hover:cursor-pointer pb-4 h-44 min-w-32 card_bg_color"
        >
          <CardBody className="p-2 h-screen flex items-center justify-center">
            <PlusIcon className="h-20 w-20 text-sky-400 " />
          </CardBody>
        </Card>
        {thoughts.slice(0, 2).map((thought, index) => (
          <Card
            key={index}
            onClick={() => openSpecificThoughtClick(thought)}
            className="hover:cursor-pointer pb-4 h-44 overflow-hidden min-w-32 card_bg_color card_text_color"
          >
            <CardBody className="p-2">
              {truncate(thought.response, 150)}
            </CardBody>
          </Card>
        ))}
      </div>
      <div className=" flex items-center justify-center  ">
        {thoughts.length > 2 ? (
          <div className="flex items-center justify-center hover:cursor-pointer">
            <Tooltip
              content="View More"
              placement="top-end"
              className="text-white bg-sky-800 font-bold p-2"
            >
              <div
                onClick={handleSeeMoreClick}
                className="arrowForMore card_bg_color"
              >
                <ArrowRightIcon className="arrow-icon h-6 w-6 text-sky-400 " />
              </div>
            </Tooltip>
          </div>
        ) : (
          <div className="h-12 w-12"></div>
        )}
      </div>
    </>
  );
};

const HomePage = () => {
  return (
    <div>
      <div>
        <div className=" my-3 font-bold heading_text_color">Interaction</div>
        <div className="flex gap-4">
          <ThoughtProcess></ThoughtProcess>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
