import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactNode } from "react";
import { MdHomeFilled, MdOutlinePrivacyTip } from "react-icons/md";
import { RiQuestionAnswerFill } from "react-icons/ri";
import { getId } from "../../api";

// import { getId } from "../../api";
export interface SideBarIconProps {
  icon: ReactNode;
  text: string;
  to: string;
  response?: string;
  conversation_id?: string;
}

export const SideBarIcon = ({ icon, text, to }: SideBarIconProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  // const  {uuid} = useParams();
  // console.log("uuid: ", params)
  let isActive = location.pathname.includes(to);
  // if (location.pathname === `/caselets/${params.Id}` ) {
  //     isActive = false;
  //   }
  const handleClick = async () => {
    // setIsActive(!isActive)
    if (to === "/thoughts" && !isActive) {
      try {
        console.log("clicked");
        const idResponse = await getId();
        if (idResponse.status === 401 || idResponse.status === 422) {
          // navigate("/user_login")
        }
        if (!idResponse.ok) {
          throw new Error(`Failed to fetch ID: ${idResponse.status}`);
        }
        const id = await idResponse.json();
        console.log("id: ", id);
        navigate(`${params.uuid}/thoughts/${id.Id}`, {
          state: { from: "new" },
        });
      } catch (error) {
        console.error("Failed to fetch ID and navigate", error);
      }
    } else if (to === "/caselets" && !isActive) {
      navigate(to || "/caselets");
    } else if (to === "/workbench") {
      navigate(`/${params.uuid}`);
    } else if (to === "/privacy") {
      navigate(`/${params.uuid}/privacy`);
    }
  };
  return (
    <div
      onClick={handleClick}
      className={`sidebar-icon group ${isActive ? "activeSideNav" : ""}`}
    >
      {icon}
      <span className="sidebar-tooltip group-hover:scale-100">{text}</span>
    </div>
  );
};

const SideBar = () => {
  return (
    <div
      className="fixed top-16 left-0 w-16 flex flex-col justify-between
            bg-slate-800 shadow-lg sidebar-height z-10"
    >
      <div>
        <SideBarIcon
          icon={<MdHomeFilled size="28" />}
          text={"Workbench"}
          to="/workbench"
        />
        <SideBarIcon
          icon={<RiQuestionAnswerFill size="28" />}
          text={"Interaction"}
          to="/thoughts"
        />
        <div className="fixed bottom-0 m-2">
          <SideBarIcon
            icon={<MdOutlinePrivacyTip size="28" />}
            text={"Privacy"}
            to="/privacy"
          />
        </div>

        {/* <SideBarIcon icon={<SiFiles size="28" />} text={"Caselets"} to="/caselets" /> */}
      </div>
    </div>
  );
};

export default SideBar;
