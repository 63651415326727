import { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { getSeedFileInfo } from "../../../api";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Typography } from "@material-tailwind/react";
import ReactQuill from "react-quill";
import { ImCross } from "react-icons/im";

const ShowCitationSource = ({ open, onClose, fileName }: any) => {
  const [seedFileData, setSeedFileData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const size = window.innerWidth * 0.5;
  function formatDate(dateString:string) {
    if (!dateString) return null;
    
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return null;
  
    return date.toISOString().split('T')[0];
  }
  async function fetchSeedsInfo(seedFileName: string) {
    setIsLoading(true);
    // setTimeout(async () => {
    try {
      seedFileName = seedFileName.substring(0, seedFileName.lastIndexOf("."));
      const seedFileData = await getSeedFileInfo(seedFileName);
      if (seedFileData.status === 401 || seedFileData.status === 422) {
        document.cookie = `mentee_token_cookie=''; path=/; SameSite=None; Secure`;
        // navigate("/user_login");
      }
      if (!seedFileData.ok) {
        throw new Error(`Error! status: ${seedFileData.status}`);
      }
      const seedData = await seedFileData.json();
      console.log("seedData: ", seedData);
      setSeedFileData(seedData);
    } catch (error: any) {
      console.error(`Failed to fetch source info `, error);
      setError(error.message); // Set error message when request fails
    } finally {
      setIsLoading(false);
    }
    // },  1000);
  }

  useEffect(() => {
    fetchSeedsInfo(fileName);
  }, []);

  const drawerContent = isLoading ? (
    <div className=" p-5  justify-center items-center h-full card_bg_color card_text_color">
       <ImCross onClick={onClose} className="cursor-pointer"/>
      <div className="max-w-full animate-pulse">
        <Typography
          as="div"
          variant="paragraph"
          className="mb-2 h-2 w-6/12 rounded-full bg-gray-500"
        >
          &nbsp;
        </Typography>
        <Typography
          as="div"
          variant="paragraph"
          className="mt-10 mb-2 h-2 w-4/12 rounded-full bg-gray-500"
        >
          &nbsp;
        </Typography>
        <Typography
          as="div"
          variant="paragraph"
          className="mb-2 h-2 w-2/12 rounded-full bg-gray-500"
        >
          &nbsp;
        </Typography>
        <Typography
          as="div"
          variant="paragraph"
          className="mt-10 mb-2 h-2 w-2/12 rounded-full bg-gray-500"
        >
          &nbsp;
        </Typography>
        <Typography
          as="div"
          variant="paragraph"
          className="mb-2 h-2 w-11/12 rounded-full bg-gray-500"
        >
          &nbsp;
        </Typography>
        <Typography
          as="div"
          variant="paragraph"
          className=" mb-2 h-2 w-11/12 rounded-full bg-gray-500"
        >
          &nbsp;
        </Typography>
        <Typography
          as="div"
          variant="paragraph"
          className=" mb-2 h-2 w-11/12 rounded-full bg-gray-500"
        >
          &nbsp;
        </Typography>
        <Typography
          as="div"
          variant="paragraph"
          className=" mb-2 h-2 w-11/12 rounded-full bg-gray-500"
        >
          &nbsp;
        </Typography>
        <Typography
          as="div"
          variant="paragraph"
          className=" mb-2 h-2 w-11/12 rounded-full bg-gray-500"
        >
          &nbsp;
        </Typography>
      </div>
    </div>
  ) : error ? (
    <div className="p-5 text-center font-bold card_bg_color h-full card_text_color">
       <ImCross onClick={onClose} className="cursor-pointer"/>
      <h2>Oops, something went wrong.</h2>
      {/* <p>{error}</p> */}
    </div>
  ) : (
    
    <div className="p-4  pb-20 card_bg_color card_text_color ">
      <ImCross onClick={onClose} className="cursor-pointer"/>
      {seedFileData?.title && (
        <div className="flex mb-5 text-lg justify-center">
          {/* <h1> */}
          {/* <span className="font-bold"> Title: </span> */}
          {/* {seedFileData?.title} */}
          <a
            href={seedFileData?.link}
            target="_blank"
            className=" link_text_color  "
          >
            {seedFileData?.title}{" "}
          </a>
          {/* </h1> */}
        </div>
      )}
      {seedFileData?.author && (
        <div className="flex mb-5 w-full">
          {/* <h5>
            <span className="font-bold">Author: </span>
            {seedFileData?.author}
          </h5>
          <p className="">
            <span className="font-bold">Date: </span>
            {seedFileData?.date}
          </p> */}
          <div className="w-full text-left">
            <span className="font-bold">Author: </span>
            {seedFileData?.author}
          </div>
          <div className="w-full text-right">
            <span className="font-bold">Date: </span>
            {
            formatDate(seedFileData.date)}
          </div>
        </div>
      )}
      {seedFileData?.description && (
        <div className="mb-5 text-justify">
          <h3 className="font-bold">Description:</h3>
          {/* <p>{seedFileData?.description}</p> */}
          <div className=" min-w-96 overflow-auto">
            <ReactQuill
              // theme="snow"
              modules={{ toolbar: false }}
              readOnly={true}
              value={seedFileData?.description}
              className=""
              style={{
                // backgroundColor: "white",
                height: "60vh",
                // marginTop:"1rem"
              }}
              // style={{
              //   boxSizing: 'border-box',
              //   border: '1px solid #d61c1c',
              //   fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
              //   height:"300px",
              //   backgroundColor:"white"
              //   // Add other styles as needed
              // }}
            />
          </div>
        </div>
      )}
      {seedFileData?.suggestion && (
        <div className="mb-5 text-justify">
          <h2 className="font-bold">Suggestion:</h2>
          <p className="pb-10">{seedFileData?.suggestion}</p>
        </div>
      )}
      {!seedFileData?.title &&
        !seedFileData?.author &&
        !seedFileData?.description &&
        !seedFileData?.suggestion && (
          <div className="text-center font-bold">
            <h2>Source not found.</h2>
          </div>
        )}
    </div>
  );

  return (
    <div className="">
      <Drawer
        open={open}
        onClose={onClose}
        direction="right"
        size={size}
        duration={1000}
        className={`bg-gray-150 border-2 border-solid border-gray-400 mt-16 overflow-y-scroll mb-10 scroll `}
      >

        {drawerContent}
      </Drawer>
    </div>
  );
};

export default ShowCitationSource;
