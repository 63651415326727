// export const linkToUnPublishedCaseletInPDF ="https://mentor.pm-powerconsulting.com/#/unpublishedcaselets";

// export const linkToCaseletInPDF ="https://mentor.pm-powerconsulting.com/#/caselets";
const getBaseURL = () => {
  const { hostname } = window.location;
  console.log("hostname: ", hostname);

  // Use different API endpoints based on production domains
  if (hostname === "chai.pm-powerconsulting.com") {
    return "https://chai.pm-powerconsulting.com/api";
  }

  if (hostname === "ask.pm-powerconsulting.com") {
    return "https://ask.pm-powerconsulting.com/api";
  }
  // for testing env
  if (hostname === "192.168.16.135") {
    return "http://192.168.16.135/api";
  }

  // Use local API for localhost
  return `http://localhost:7000/api`;
};
export const LINK_TO_UNPUBLISHED_CASELET_IN_PDF =
  import.meta.env.VITE_BASE_LINK_TO_CASELET_IN_PDF + "/#/unpublishedcaselets";
export const LINK_TO_PUBLISHED_CASELET_IN_PDF =
  import.meta.env.VITE_BASE_LINK_TO_CASELET_IN_PDF + "/#/caselets";
export const Chat: string = "chat";
export const Seed: string = "seed";
export const caselet: string = "caselet";
// export const BaseURL: string = "http://localhost:7000/api";
export const unPublishCaselet: string = "unPublishCaselet";
// export const BaseURL:string = "https://mentor.pm-powerconsulting.com/api"
export const caseletView: string = "2";
export const loadNumberOfFiles = 5;
// export const GOOGLE_CLIENT_ID = "490827647833-95s76pqad11v1v6kkisiisai8jtts80v.apps.googleusercontent.com";
export const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;
export const BaseURL = getBaseURL();
// export const BASE_FRONT_END_URL =  import.meta.env.VITE_BASE_LINK
