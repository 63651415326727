import Avatar from "../../../design-system/Avatar";
import { RenderResponse } from "./RenderResponse";
import { RenderCitations } from "./RenderCitations";
import RenderFollowupQuestions from "./RenderFollowupQuestions";
import { RelatedCaseletProps } from "./RenderFollowupCaselet";
import {
  HiHandThumbDown,
  HiHandThumbUp,
  HiOutlineHandThumbDown,
  HiOutlineHandThumbUp,
} from "react-icons/hi2";
import { useEffect, useState } from "react";
import { ChatFeedBack } from "../../../api";
import { Tooltip } from "@material-tailwind/react";

export type ChatCardProps = {
  id?: string;
  index?: number;
  feedBack?: string;
  prompt?: string;
  response?:
    | null
    | string
    | string[]
    | JSX.Element[]
    | JSON
    | React.MouseEventHandler;
  citations?:
    | null
    | string
    | string[]
    | JSX.Element[]
    | { text: string; link: string }
    | { text: string; link: string }[]
    | Array<{ citation: string; link: string; title: string; author: string }>;
  isResponseEmptyFlag?: boolean;
  isLoading?: boolean;
  responseForFineTune?: string;
  conversation_id?: string;
  followupQuestions?: string[];
  relatedCaselet?: RelatedCaseletProps[];
  onCitationClick?: (path: string) => void;
  onFollowupQuestionClicked?: (question: string) => void;
};

const ChatCard: React.FC<ChatCardProps> = ({
  prompt,
  index,
  response,
  citations,
  feedBack,
  followupQuestions,
  isResponseEmptyFlag,
  conversation_id,
  onCitationClick,
  onFollowupQuestionClicked,
}: ChatCardProps) => {
  const isLoading = response === null || response === "" || response === "";
  const [isThumbUpClicked, setIsThumbUpClicked] = useState<Boolean>(false);
  const [isThumbDownClicked, setIsThumbDownClicked] = useState<Boolean>(false);
  // console.log("conversation_id: ", conversation_id, index)
  const getInitials = (name: string) => {
    return name
      .split(" ")
      .map((n) => n[0])
      .join("");
  };

  useEffect(() => {
    if (feedBack === "bad") {
      setIsThumbDownClicked(true);
    }
    if (feedBack === "good") {
      setIsThumbUpClicked(true);
    }
  }, []);
  const isCitationsEmpty =
    !citations ||
    (Array.isArray(citations) && citations.length === 0) ||
    (typeof citations === "object" &&
      !Array.isArray(citations) &&
      Object.keys(citations).length === 0);
  let menteeData: any = localStorage.getItem("mentee_data");
  if (menteeData) {
    try {
      menteeData = JSON.parse(menteeData);
    } catch (e) {
      console.error("Error parsing mentee_data from localStorage:", e);
      menteeData = null;
    }
  } else {
    menteeData = null;
  }
  const menteeName = menteeData ? menteeData.name : "John Doe";
  const handleThumbsUp = async () => {
    if (!isThumbUpClicked) {
      const res = await ChatFeedBack(conversation_id, "good", index);
      if (res.ok) {
        const resJson = await res.json();
        if (resJson.status !== "failed") {
          setIsThumbUpClicked(!isThumbUpClicked);
          setIsThumbDownClicked(false);
        }
      }
    } else {
      const res = await ChatFeedBack(conversation_id, "", index);
      if (res.ok) {
        const resJson = await res.json();
        if (resJson.status !== "failed") {
          setIsThumbUpClicked(!isThumbUpClicked);
          setIsThumbDownClicked(false);
        }
      }
    }
  };
  const handleThumbsDown = async () => {
    if (!isThumbDownClicked) {
      const res = await ChatFeedBack(conversation_id, "bad", index);

      if (res.ok) {
        const resJson = await res.json();
        if (resJson.status !== "failed") {
          setIsThumbUpClicked(false);
          setIsThumbDownClicked(!isThumbDownClicked);
        }
      }
    } else {
      const res = await ChatFeedBack(conversation_id, "", index);

      if (res.ok) {
        const resJson = await res.json();
        if (resJson.status !== "failed") {
          setIsThumbUpClicked(false);
          setIsThumbDownClicked(!isThumbDownClicked);
        }
      }
    }
  };
  return (
    <div className="">
      <div className="flex items-center p-2">
        <div className="max-w-14">
          <Avatar
            initials={getInitials(menteeName)}
            fullName={menteeName}
            className="mr-4 shadow-lg "
          />
        </div>
        <div>
          <p className="text-lg font-semibold   max-h-14 overflow-auto card_text_color ">
            {prompt}
          </p>
        </div>
      </div>
      <div className="">
        <div className="mb-6 ">
          <div className="p-4 mb-2 rounded-lg shadow-lg relative card_text_color card_bg_color">
            <div className="">
              <RenderResponse isLoading={isLoading} response={response} />
              {!isCitationsEmpty && <div className="mt-5"></div>}
              <div className="pl-2 grid w-full">
                <RenderCitations
                  isLoading={isLoading}
                  citations={citations}
                  onCitationClick={onCitationClick}
                />
                {!isLoading && citations !=="" && (
                  <div className=" text-end w-full">
                    <div className="flex justify-end space-x-6 mt-4 ">
                      {isThumbUpClicked ? (
                        // clicked
                        <Tooltip
                          content="Liked"
                          placement="top"
                          className="text-white bg-slate-800 border-0		border-slate-100 font-bold "
                        >
                          <button
                            onClick={handleThumbsUp}
                            className=" text-amber-400  cursor-pointer"
                          >
                            <HiHandThumbUp size={26} />
                          </button>
                        </Tooltip>
                      ) : (
                        //not clicked
                        <Tooltip
                          content="Like"
                          placement="top"
                          className="text-white bg-slate-800 font-bold"
                        >
                          <button
                            onClick={handleThumbsUp}
                            className=" text-amber-400  cursor-pointer"
                          >
                            <HiOutlineHandThumbUp size={26} />
                          </button>
                        </Tooltip>
                      )}

                      {isThumbDownClicked ? (
                        // clicked
                        <Tooltip
                          content="Disliked"
                          placement="top"
                          className="text-white bg-slate-800 font-bold"
                        >
                          <button
                            onClick={handleThumbsDown}
                            className=" text-amber-400  cursor-pointer"
                          >
                            <HiHandThumbDown size={26} />
                          </button>
                        </Tooltip>
                      ) : (
                        //not clicked
                        <Tooltip
                          content="Dislike"
                          placement="top"
                          className="text-white bg-slate-800 font-bold "
                        >
                          <button
                            onClick={handleThumbsDown}
                            className=" text-amber-400  cursor-pointer"
                          >
                            <HiOutlineHandThumbDown size={26} />
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {isResponseEmptyFlag && conversation_id && prompt && (
                <p>Oops, something went wrong </p>
              )}
            </div>
          </div>
          {!isResponseEmptyFlag ? (
            <div className="flex">
              {followupQuestions?.length ? (
                <div className="w-1/2">
                  <RenderFollowupQuestions
                    followupQuestions={followupQuestions}
                    onFollowupQuestionClicked={onFollowupQuestionClicked}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatCard;
