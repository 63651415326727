import { useCallback } from "react";
import { ChatCardProps } from "./ChatCard";

const RenderFollowupQuestions = ({
  followupQuestions,
  onFollowupQuestionClicked,
}: ChatCardProps) => {
  let timeoutId:any;

  const debouncedOnClick = useCallback((item:any) => {
    clearTimeout(timeoutId);
    
    timeoutId = setTimeout(() => {
      onFollowupQuestionClick(item);
    }, 300); // 300ms delay
  }, [onFollowupQuestionClick]);


  function onFollowupQuestionClick(question: string): void {

    if (onFollowupQuestionClicked) {
      onFollowupQuestionClicked(question);
    }
  }
  return (
    <div>
      {Array.isArray(followupQuestions) ? (
        <ol className=" pl-6">
         { followupQuestions.length? <div className=" font-title">Follow up Questions</div>:""}
          {followupQuestions.map((item, index) => (
            <div className="" key={index}>
          
          {/* text-blue-500 */}
                <button
                  className="cursor-pointer 	link_text_color hover:text-white  mt-1 text-left"
                  // onClick={() => onFollowupQuestionClick(item)}
                  onClick={() => debouncedOnClick(item)}

                >
                  <div className="p-2 card_bg_color rounded-md hover:bg-slate-600 ">
                    {item.toString().replace(/_/g, " ")}
                  </div>
                </button>
    
            </div>
          ))}
        </ol>
      ) : typeof followupQuestions === "string" ? (
        <p>{followupQuestions}</p>
      ) : (
        followupQuestions && (
          <a href={followupQuestions} target="_blank" rel="noopener noreferrer">
            {followupQuestions}
          </a>
        )
      )}
    </div>
  );
};

export default RenderFollowupQuestions;
