import { useState, useEffect } from "react";
import {
  Outlet,
  useLocation,
  useParams,
} from "react-router-dom";
import styles from "./Layout.module.css";
import Avatar from "../../design-system/Avatar";
import SideBar from "./Sidebar";
import {
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BaseURL } from "../../utils/appConstant";
import UrlNotFound from "../UrlNotFound";

const Layout = () => {
  const location = useLocation();
  const params = useParams();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  // Determine layout title based on current route
  // const layOutTitle = location.pathname === "/" || location.pathname === "/workbench"
  //   ? "Workbench"
  //   : location.pathname.includes("/thoughts") ? "Interaction" : "Workbench";
  const layOutTitle = location.pathname.includes("/workbench")
    ? "Workbench"
    : (location.pathname.includes("/privacy") ? "Privacy and Data Protection Policy" : 
      (location.pathname.includes("/thoughts") ? "Interaction":"workbench"));


  const [userDetails, setUserDetails] = useState({
    userName: "",
    userEmail: "",
    userPicture: "",
    userUuid:""
  });

  const [isLoading] = useState(true);
  const [hasFetchedData, setHasFetchedData] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const loginUsingUUID = async () => {
      if (params.uuid !== null && params.uuid !== undefined) {
        try {
          const res = await fetch(`${BaseURL}/login_through_uuid?uuid=${params.uuid}`, {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
          });
          const data = await res.json();
          console.log(isMounted)
          if (data.status === "failed") {
            setErrorOccurred(true);
          } else {
            setErrorOccurred(false);
            setUserDetails({
              ...userDetails,
              userName: data.user_data.name,
              userEmail: data.user_data.email,
              userPicture: data.user_data.picture,
              userUuid: params.uuid
            });
            localStorage.setItem("mentee_data", JSON.stringify(data.user_data));
            localStorage.setItem("mentee_uuid", params.uuid);
            
            document.cookie = `mentee_token_cookie=${data.access_token}; path=/; SameSite=None; Secure`;
            setHasFetchedData(true);
          }
        } catch (error) {
          document.cookie = `mentee_token_cookie=''; path=/; SameSite=None; Secure`;
          console.error("Error in login_using_uuid:", error);
          setErrorOccurred(true);
        }
      } else {
        setErrorOccurred(true);
      }
    };

    loginUsingUUID();

    return () => {
      isMounted = false;
    };
  }, []);

  const getInitials = (name: string | null) => {
    return name?.split(" ").map((n) => n[0]).join("");
  };
  const initials = getInitials(userDetails.userName);

  if (errorOccurred) {
    return <UrlNotFound />;
  }

  if (isLoading && !hasFetchedData) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className={`${styles.layout} w-full scroll`}>
      <header
        className={`${styles.header} sticky top-0 z-50 w-full shadow-lg`}
        role={"banner"}
      >
        <div className={`${styles.headerContainer} h-16`}>
          {/* <div className=""> */}
            <div className={styles.headerTitleContainer}>
              <h3 className={styles.headerTitle}>CH.AI</h3>
            </div>
            <h3 className="font-bold heading_text_color truncate min-w-24">{layOutTitle}</h3>
          {/* </div> */}

          <div
            className={`${styles.headerRightText} flex items-center pl-2    h-14 gap-2  rounded-lg  `}
          >
            <div className="min-w-16">
              <img src="/PM_POWER_LOGO_WHITE-2019.png" className=" justify-self-end  h-10 " />
            </div>
            <div className="min-w-10">
              <Popover
                open={isPopoverOpen}
                handler={() => setIsPopoverOpen(!isPopoverOpen)}
                placement="bottom-end"
              >
                <PopoverHandler>
                  <div className="cursor-pointer">
                    {userDetails.userPicture === null || userDetails.userPicture === "" ? (
                      <Avatar initials={initials} fullName={userDetails.userName} />
                    ) : (
                      <img
                        alt="Profile image"
                        className="JZFjpd  rounded-full h-10 w-10"
                        src={userDetails.userPicture}
                        title={userDetails.userName}
                      ></img>
                    )}
                  </div>
                </PopoverHandler>
                <PopoverContent
                  style={{ width: "80vw" }}
                  className="m-2 z-20 card_bg_color card_text_color"
                >
                  <div className="flex justify-end">
                    <button onClick={() => setIsPopoverOpen(false)}>
                      <XMarkIcon className="w-6 h-6"> </XMarkIcon>
                    </button>
                  </div>
                  <div className="mx-10">
                    <div className="w-full flex items-center justify-center">
                      <div
                        aria-label="profile picture"
                        className="w-2/3 flex items-center justify-center"
                      >
                        {userDetails.userPicture === null || userDetails.userPicture === "" ? (
                          <Avatar
                            initials={initials}
                            fullName={userDetails.userName}
                            className="w-10 h-10 font-bold text-3xl"
                          />
                        ) : (
                          <img
                            alt="Profile image"
                            className="JZFjpd  rounded-full h-20 w-20"
                            src={userDetails.userPicture}
                            title={userDetails.userName}
                          ></img>
                        )}
                      </div>
                    </div>
                    <div
                      className={` w-full flex items-center justify-center  rounded-xl`}
                    >
                      <h2 className="text-xl">{userDetails.userName}</h2>
                    </div>
                    <div
                      className={` w-full flex items-center justify-center  rounded-xl`}
                    >
                      <h2 className="">{userDetails.userEmail}</h2>
                    </div>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </div>
      </header>

      <div className="flex">
        {/* {isSidebarVisible && <SideBar />} */}
        <SideBar />
        <div
          className={`flex-1 text-m 
            pl-20
           pb-0 pt-16 pr-4 min-h-screen `}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );

};

export default Layout;
