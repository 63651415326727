import { useState, useCallback } from "react";
import { ChatCardProps } from "./ChatCard";
import { getSeedFileInfo } from "../../../api";
import "./chat.module.css"
export const RenderCitations = ({ isLoading, citations, onCitationClick }: ChatCardProps) => {
    const [, setCitationUrls] = useState<Record<string, string>>({});

    const fetchSeedsInfo = useCallback(async (seedFileName?: string | { link: string }): Promise<string | null> => {
        try {
            if (typeof seedFileName !== 'string' && seedFileName !== undefined) {
                seedFileName = seedFileName.link;
            }
    
            if (!seedFileName) {
                throw new Error('Seed filename is required');
            }
    
            const seedFileNameWithoutExtension = seedFileName.split('.')[0] || '';
            const seedFileData = await getSeedFileInfo(seedFileNameWithoutExtension);
            if (seedFileData.status === 401 || seedFileData.status === 422) {
                // delete_user_data_from_client_side();
                // navigate("/user_login");
            }
            if (!seedFileData.ok) {
                throw new Error(`Error! status: ${seedFileData.status}`);
            }
            const seedData = await seedFileData.json();
            return seedData.link; // Assuming the API returns a 'url' field
        } catch (error: any) {
            console.error(`Failed to fetch source info `, error);
            return null;
        }
    }, []);


    function onCitationClicked(path: any): void {
        if (onCitationClick) {
            fetchSeedsInfo(path).then(url => {
                if (url) {
                    setCitationUrls(prevUrls => ({
                        ...prevUrls,
                        [path]: url
                    }));
                }
            });
            // console.log("path: ", path);
            onCitationClick(path);
        }
    }

    return (
        <>
            {!isLoading && citations && Array.isArray(citations) && citations.length > 0 ? (
                <div className="w-full">
                    <div className="text-sm text-gray-500 overflow-auto max-h-[100px]">
                        <b className="heading_text_color pl-2">Citations:</b>
                        {Array.isArray(citations) ? (
                            <ol className="list-decimal citation pl-6 " >
                                {citations.map((item:any, index) => (
                                   
                                    <li key={index}>
                                        {item.link !== '' ? (
                                            <a
                                            href={item.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="cursor-pointer link_text_color hover:underline"
                                           
                                        >
                                            {item.title} <i> by {item.author}</i>
                                        </a>
                                        ):(
                                            <a
                                            rel="noopener noreferrer"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                onCitationClicked(item.citation);
                                            }}
                                            className="cursor-pointer link_text_color hover:underline"
                                           
                                        >
                                            {item.title} <i> by {item.author}</i>
                                        </a>
                                        )}
                                        
                                    </li>
                                ))}
                            </ol>
                        ) : typeof citations === "string" ? (
                            <p>{citations}</p>
                        ) : (
                            // <a href={citationUrls[citations]} target="_blank" rel="noopener noreferrer">
                            //     {citations.text}
                            // </a>
                            ""
                        )
                        }
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
};
