import React from "react";
import { ChatCardProps } from "./ChatCard";
import { Typography } from "@material-tailwind/react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
const markdownComponents = {
    // Table components
    table: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <div className="overflow-x-auto">
        <table
          className="table-auto border-collapse border border-gray-300 w-full"
          {...props}
        />
      </div>
    ),
    th: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <th className="border font-semibold" {...props} />
    ),
    td: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <td className="border  p-2" {...props} />
    ),
  
    // List components
    ol: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <ol className="list-decimal list-inside space-y-2 pl-4" {...props} />
    ),
    ul: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <ul className="list-disc list-inside space-y-2 pl-4" {...props} />
    ),
    li: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <li className="mb-1" {...props} />
    ),
  
    // Blockquote component
    blockquote: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <blockquote className="my-4 border-l-4 border-blue-500 pl-4 italic" {...props} />
    ),
  
    pre: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <pre className="bg-gray-100 rounded p-2 overflow-x-auto" {...props} />
    ),
  
    // Heading components
    h1: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <h1 className="text-3xl font-bold mt-4 mb-2" {...props} />
    ),
    h2: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <h2 className="text-2xl font-semibold mt-4 mb-2" {...props} />
    ),
    h3: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <h3 className="text-xl font-medium mt-4 mb-2" {...props} />
    ),
    h4: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <h4 className="text-lg font-semibold mt-2 mb-1" {...props} />
    ),
    h5: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <h5 className="text-base font-medium" {...props} />
    ),
    h6: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <h6 className="text-sm font-semibold" {...props} />
    ),
  
    // Paragraph component
    p: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <p className="mb-2 " {...props} />
    ),
  
    // Emphasis components
    em: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <em className="font-italic " {...props} />
    ),
    strong: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <strong className="font-bold " {...props} />
    ),
  
    // Link component
    a: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <a className="text-blue-600 hover:underline" target="__blank" {...props} />
    ),
  
    // Bold component
    b: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <b className="font-bold" {...props} />
    ),
  
    // Italic component
    i: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <i className="italic" {...props} />
    ),
  
    // Strike-through component
    s: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <s className="line-through" {...props} />
    ),
  
    // Underline component
    u: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <u className="underline" {...props} />
    ),
  
    // Superscript component
    sup: ({ node, ...props }: { node?: any; [key: string]: any }) => (
        // <div className=" inline text-yellow-900">
      <sup className="text-xs supClass cursor-none" {...props} />
    //   </div>
    ),
  
    // Subscript component
    sub: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <sub className="text-xs" {...props} />
    ),
  
    // Horizontal rule component
    hr: ({ node, ...props }: { node?: any; [key: string]: any }) => (
      <hr className="my-4 border-gray-300 " {...props} />
    )
  };
  
export const RenderResponse = ({ isLoading, response }: ChatCardProps) => {
    if (isLoading) {
        return (
            <div className="max-w-full animate-pulse">
                <Typography as="div" variant="paragraph" className="mb-2 h-2 w-11/12 rounded-full bg-gray-500">
                    &nbsp;
                </Typography>
                <Typography as="div" variant="paragraph" className="mb-2 h-2 w-10/12 rounded-full bg-gray-500">
                    &nbsp;
                </Typography>
                <Typography as="div" variant="paragraph" className="mb-2 h-2 w-2/12 rounded-full bg-gray-500">
                    &nbsp;
                </Typography>
                <Typography as="div" variant="paragraph" className="mb-2 h-2 w-6/12 rounded-full bg-gray-500">
                    &nbsp;
                </Typography>
            </div>
        );
    } else if (typeof response === "string") {
        return (
            <ReactMarkdown rehypePlugins={[rehypeRaw]} components={
            markdownComponents
              }>
                {response}
              </ReactMarkdown>
        );
    } else if (Array.isArray(response)) {
        let contentWithLineBreaks = response.map((item, index) => {
            // Check if the next item exists and is a string
            const nextItemExists = response[index + 1] !== undefined;
            const nextItemIsString = nextItemExists ? typeof response[index + 1] === "string" : false;
            if (typeof item === "string" && nextItemIsString) {
                return (
                    <React.Fragment key={index}>
                        {item}
                        <br />
                        <br />
                    </React.Fragment>
                );
            } else {
                return <React.Fragment key={index}>{item}</React.Fragment>;
            }
        });
        return <div className="p-4 text-base  leading-relaxed scroll-style overflow-auto min-h-[100px] max-h-[300px] ">{contentWithLineBreaks}</div>;
    }
    return null;
};
