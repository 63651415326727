import { Typography } from "@material-tailwind/react";
import { Card } from "@material-tailwind/react";

export function CardSkeleton() {
    return (
        <div className="flex space-x-4 animate-pulse  gap-4 w-full">

            <Card className="flex flex-col space-y-2 w-1/3 h-44 overflow-hidden card_bg_color card_text_color">
            <div className="p-4">
                <Typography as="div" variant="h1" className="mb-4 h-3  w-6/12 rounded-full bg-gray-500">
                    {""}
                </Typography>
                <Typography as="div" variant="paragraph" className="mb-2 h-2 w-12/12 rounded-full bg-gray-500">
                    {""}
                </Typography>
                <Typography as="div" variant="paragraph" className="mb-2 h-2 w-11/12 rounded-full bg-gray-500">
                    {""}
                </Typography>
            </div>
            </Card>
            <Card className="flex flex-col space-y-2  w-1/3 h-44 overflow-hidden card_bg_color card_text_color">
            <div className="p-4">
                <Typography as="div" variant="h1" className="mb-4 h-3  w-6/12 rounded-full bg-gray-500">
                    {""}
                </Typography>
                <Typography as="div" variant="paragraph" className="mb-2 h-2 w-12/12 rounded-full bg-gray-500">
                    {""}
                </Typography>
                <Typography as="div" variant="paragraph" className="mb-2 h-2 w-11/12 rounded-full bg-gray-500">
                    {""}
                </Typography>
            </div>
            </Card>
            <Card className="flex flex-col space-y-2 w-1/3 h-44 card_bg_color card_text_color">
            <div className="p-4">
                <Typography as="div" variant="h1" className="mb-4 h-3  w-6/12 rounded-full bg-gray-500">
                    {""}
                </Typography>
                <Typography as="div" variant="paragraph" className="mb-2 h-2 w-12/12 rounded-full bg-gray-500">
                    {""}
                </Typography>
                <Typography as="div" variant="paragraph" className="mb-2 h-2 w-11/12 rounded-full bg-gray-500">
                    {""}
                </Typography>
            </div>
            </Card>
        </div>
    );
}
