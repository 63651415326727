
interface AvatarProps {
    initials?: string;
    imageUrl?: string;
    className?: string;
    fullName?: string;
}

const Avatar = ({ initials, imageUrl, className, fullName }: AvatarProps) => {
    return (
        <div
            className={`h-10 w-10 rounded-full flex items-center justify-center bg-sky-900 text-white text-sm font-semibold uppercase ${className}`}
            title={fullName}
        >
            {imageUrl ? (
                <img src={imageUrl} alt={initials} className="rounded-full" />
            ) : (
                initials
            )}
        </div>
    );
};

export default Avatar;