// hooks/useUserData.js
import { useState, useEffect } from 'react';

interface UserData {
    email: string;
    name: string;
    picture: string;
  } 

const useUserData = (): UserData | null=> {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const getMenteeData = () => {
      let userData = localStorage.getItem("mentee_data");
      if (userData) {
        try {
          setUserData(JSON.parse(userData));
        } catch (e) {
          console.error("Error parsing mentee_data from localStorage:", e);
          setUserData(null);
        }
      } else {
        setUserData(null);
      }
    };

    getMenteeData();
  }, []);

  return userData;
};

export default useUserData;
