import "./Privacy.module.css"; // Add this import and create a new file named Privacy.css

function Privacy() {
  return (
    <div className="card_text_color m-1 ">
      <div className="card_bg_color my-5 card_text_color rounded-md ">
        <div className="m-2">
          {" "}
          Our app values your privacy and is dedicated to keeping your data safe
          and secure. We prioritize protecting your personal information with
          robust security measures.
        </div>
        <div className="mx-6 m-2 leading-7 list-decimal ">
          <div className="list-item">
            <div>
              All questions you ask and responses you receive are private and
              visible only to you. No one else can view or access this
              information, keeping your experience within the app completely
              confidential.
            </div>
          </div>
          <div className="list-item">
            <div>
              To improve our app, a team within our company may review general
              questions, responses, and feedback. This information is always
              anonymous and cannot be connected to individual users. Our team
              uses only anonymous data to improve the app experience for all
              users.
            </div>
          </div>
          <div className="list-item">
            <div>
              In specific cases, a designated administrator may access user data
              to fulfill legal obligations.
            </div>
          </div>

          <div className="list-item">
            {/* <div>
              <strong>Anonymized Analytics for Enhanced Functionality</strong>{" "}
            </div>{" "} */}
            <div>
              Our app gathers data to analyze trends and improve functionality.
              This information is fully anonymized so it cannot be traced back
              to individual users.
            </div>
          </div>
          <div className="list-item">
            {/* <div>
              <strong>Ongoing Security and Privacy Enhancements</strong>{" "}
            </div>{" "} */}
            <div>
              We use strong security measures, such as encryption and access
              controls, to keep your data safe and prevent unauthorized access.
              We continually review and update our practices to ensure high
              standards of privacy and security.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
