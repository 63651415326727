import React, { useState, useRef, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./answer/Answer.module.css";
import ChatCard from "./chat/ChatCard";
import { parseAnswerToHtml } from "./answer/AnswerParser";
import { TextAreaWithButton } from "./TextAreaWithButton";
import { checkFile, generateChat, getFileChats } from "../../api";
import ShowCitationSource from "./chat/ShowCitationSource";
import ErrorBoundary from "../../utils/ErrorBoundry";
import { isValidEpochTime } from "../../utils/common";
import NoPage from "../NoPage";
import { RelatedCaseletProps } from "./chat/RenderFollowupCaselet";
import { BaseURL } from "../../utils/appConstant";
// import useRefreshToken from "../../utils/useRefreshToken";
interface fetchGeneratedResponseUsingPromptProps {
  id: string | undefined;
  userInput: string;
}
interface ThoughtsProps {
  prompt: string;
  response: string | string[] | JSX.Element;
  citations?:
    | string
    | string[]
    | JSX.Element[]
    | { text: string; link: string }
    | { text: string; link: string }[];
  followupQuestions?: string[];
  relatedCaselet?: RelatedCaseletProps[];
}

interface GeneratedChatListProps {
  generatedChat: JSX.Element[];
}
const GeneratedChatList = ({ generatedChat }: GeneratedChatListProps) => {
  return (
    <div className=" items-center justify-center mb-28 w-full  ">
      {generatedChat.map((chat, index) =>
        React.cloneElement(chat, { key: index })
      )}
    </div>
  );
};

const Thoughts = ({ prompt: initialPrompt }: ThoughtsProps) => {
  const [isError, setIsError] = useState(true);
  const [prompt, setPrompt] = useState(initialPrompt);
  const [generatedChat, setGeneratedChat] = useState<JSX.Element[]>([]);
  const location = useLocation();
  const lastCardRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const [openRight, setOpenRight] = useState(false);
  const [fileName, setFileName] = useState<string>();
  const [showRegenerateButton, setShowRegenerateButton] = useState(false);
  let index = 0;

  const openDrawerRight = () => {
    setOpenRight(true);
  };
  const closeDrawerRight = () => {
    setOpenRight(false);
    setShowCitationSource(false);
  };
  const [showCitationSource, setShowCitationSource] = useState(false);

  function onCitationClicked(path: string) {
    //console.log("Citation clicked", path);
    setFileName(path);
    setShowCitationSource(true);
    openDrawerRight();
  }

  async function onFollowupQuestionClicked(question: string) {
    fetchGeneratedResponseUsingPrompt({
      id: params.thought_id,
      userInput: question,
    });
  }

  useEffect(() => {
    setShowCitationSource(false);
  }, [setShowCitationSource]);

  function filterAndSortCitationWithLink(
    citations: string[],
    citationWithLink: {
      citation: string;
      link: string;
      author: string;
      title: string;
    }[]
  ): { citation: string; link: string; author: string; title: string }[] {
    // Extract filenames from citations
    const citationFilenames = citations.map(
      (citation) => citation.split("/").pop() || ""
    );

    // Filter and sort citationWithLink based on citations
    return citationFilenames.reduce((acc, filename) => {
      const match = citationWithLink.find((item) =>
        item.citation.endsWith(filename)
      );
      return match ? [...acc, match] : acc;
    }, [] as typeof citationWithLink);
  }

  async function fetchAndSetThoughts(id: string | undefined) {
    try {
      let checkFileStatus = await checkFile(id);
      if (checkFileStatus.status === 401 || checkFileStatus.status === 422) {
        document.cookie = `mentee_token_cookie=''; path=/; SameSite=None; Secure`;
        // navigate("/user_login");
        const loginUsingUUID = async () => {
          console.log("params.uuid: ", params.uuid);
          if (params.uuid !== null && params.uuid !== undefined) {
            // console.log("kjadsgfkgkdsu")
            try {
              const res = await fetch(
                `${BaseURL}/login_through_uuid?uuid=${params.uuid}`,
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    // 'Authorization': `Bearer ${token}`
                  },
                  // credentials: "include",
                }
              );
              const data = await res.json();
              // console.log("data: ", data);
              localStorage.setItem(
                "mentee_data",
                JSON.stringify(data.user_data)
              );
              // document.cookie = `mentee_token_cookie=${data.access_token}; path=/; SameSite=None; Secure`;
              // if(data.status === 'failed') {
              //   // setErrorOccurred(true);
              // } else {
              //   // setErrorOccurred(false);
              //   checkFileStatus = await checkFile(id);
              // }
              if (data.status !== "failed") {
                // setErrorOccurred(true);
                checkFileStatus = await checkFile(id);
              }
            } catch (error) {
              console.error("Error in login_using_uuid:", error);
              // setErrorOccurred(true);
              // setIsLoaded(true);
            }
          } else {
            // setIsLoaded(true);
          }
        };
        loginUsingUUID();
      }
      const fileExist = await checkFileStatus.json();
      if (!fileExist.status) {
        throw new Error("File not exists.");
      }

      if (!fileExist.status) {
        throw new Error("File not exists.");
      }
      const historyResponse = await getFileChats(id);
      if (historyResponse == null) {
        throw new Error();
      }
      const data = await historyResponse.json();
      // console.log("data: ", data)
      for (const item of data) {
        const citationWithLink = item.citations;
        const { answerHtml, citations, followupQuestions, relatedCaselets } =
          parseAnswerToHtml(
            item.response,
            item.followup_questions,
            item.caselets,
            // onCitationClicked,
            citationWithLink
            // onFollowupQuestionClicked
          );
 
        const sortedCitationWithLink = filterAndSortCitationWithLink(
          citations,
          citationWithLink
        );

        const filteredCitations = sortedCitationWithLink
          .filter((citationObj: any) =>
            citations.includes(citationObj.citation.split("/").pop())
          )
          .map((item: any) => {
            const lastPart = item.citation.split("/").pop() || "";
            return {
              citation: lastPart,
              link: item.link,
              title: item.title,
              author: item.author,
            };
          });
          let feed_back_value = "not_available";
          // console.log("item.citation", item.feed_back)
          if(item.feed_back === undefined || item.feed_back === ''){
            feed_back_value = "not_available";
          }
          else{
            feed_back_value = item.feed_back;
          }
          // console.log("feed_back_value", feed_back_value)

        // console.log("filteredCitations: ", filteredCitations);
        const newChat = (
          <ChatCard
            key={generatedChat.length}
            index={index}
            prompt={item.query}
            feedBack={feed_back_value}
            response={answerHtml}
            citations={filteredCitations}
            followupQuestions={followupQuestions}
            relatedCaselet={relatedCaselets}
            conversation_id={id}
            onCitationClick={onCitationClicked}
            onFollowupQuestionClicked={onFollowupQuestionClicked}
          />
        );
        setGeneratedChat((prevChat) => [...prevChat, newChat]);
        // console.log("index: ", index)
        index++;
      }

      // console.log("count is ", count);
    } catch (err) {
      console.log("checkFile failed in thought page");
    } finally {
      setIsLoading(false);
    }
  }

  //this method is used to generate the chats using Openai and set in card
  const fetchDataAndGenerateCard = async (id: string | undefined) => {
    fetchGeneratedResponseUsingPrompt({ id: id, userInput: prompt });
  };

  async function fetchGeneratedResponseUsingPrompt({
    id,
    userInput,
  }: fetchGeneratedResponseUsingPromptProps) {
    try {
      setIsLoading(true);
      const initialEmptyCard = (
        <ChatCard
          key={generatedChat.length}
          prompt={userInput}
          response=""
          citations={[]}
          isLoading={isLoading}
        />
      );

      // Update the generatedChat state with the initial empty card
      setGeneratedChat((previousChatCard) => [
        ...previousChatCard,
        initialEmptyCard,
      ]);

      const queryResponse = await generateChat(id, userInput);
      if (queryResponse.status === 401 || queryResponse.status === 422) {
        // document.cookie = `mentee_token_cookie=''; path=/; SameSite=None; Secure`;
        // navigate("/user_login");
      }

      if (!queryResponse.ok) {
        throw new Error(`Error status: ${queryResponse.status}`);
      }
      const result = await queryResponse.json();
      const citationWithLink = result.citations;
      var updatedCard: any;
      if (result.response && result.response.trim().length > 0) {
        const { answerHtml, citations, followupQuestions, relatedCaselets } =
          parseAnswerToHtml(
            result.response,
            result.followup_questions,
            result.caselets,
            // onCitationClicked,
            citationWithLink
          );
        // const filteredCitations = citationWithLink
        // .filter((citationObj: any) => citations.includes(citationObj.citation.split("/").pop()))
        // .map((item: any) => {
        //     // console.log("item: ", item)
        //     const lastPart = item.citation.split("/").pop() || "";
        //     return {
        //         citation: lastPart,
        //         link: item.link,
        //         title: item.title,
        //         author: item.author

        //     };
        // });
        const sortedCitationWithLink = filterAndSortCitationWithLink(
          citations,
          citationWithLink
        );

        const filteredCitations = sortedCitationWithLink
          .filter((citationObj: any) =>
            citations.includes(citationObj.citation.split("/").pop())
          )
          .map((item: any) => {
            // console.log("item: ", item)
            const lastPart = item.citation.split("/").pop() || "";
            return {
              citation: lastPart,
              link: item.link,
              title: item.title,
              author: item.author,
            };
          });
          console.log("generatedChat.length: ", index )

        updatedCard = (
          <ChatCard
            key={generatedChat.length}
            prompt={userInput}
            index={index}
            response={answerHtml}
            feedBack={""}
            citations={filteredCitations}
            conversation_id={id}
            followupQuestions={followupQuestions}
            relatedCaselet={relatedCaselets}
            onCitationClick={onCitationClicked}
            onFollowupQuestionClicked={onFollowupQuestionClicked}
          />
        );
      } else {
        // If the response is empty, show a "Regenerate" button
        setShowRegenerateButton(true);
        updatedCard = (
          <ChatCard
            key={generatedChat.length}
            prompt={userInput}
            response=""
            conversation_id={id}
            isResponseEmptyFlag={true}
          />
        );
      }
      setGeneratedChat((prevChat) => {
        const updatedChat = [...prevChat];
        updatedChat[updatedChat.length - 1] = updatedCard;
        return updatedChat;
      });
    } catch (err) {
      console.log("error in fetch and generate card");
      const errorCard = (
        <ChatCard
          key={generatedChat.length}
          prompt={userInput}
          response={
            "<div className='flex  justify-center h-44 w-full font-bold p-6'>Oops, something went wrong  </div>"
          }
          citations={""}
          followupQuestions={[]}
          relatedCaselet={[]}
        />
      );
      setGeneratedChat((prevChat) => {
        const updatedChat = [...prevChat];
        updatedChat[updatedChat.length - 1] = errorCard;
        return updatedChat;
      });
    } finally {
      index++;
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const from = location.state?.from ?? "individual";
    // console.log("location.state: ", location.state);
    if (from === "individual") {
      // setGeneratedChat([]);
      // console.log("id:: ", params.thought_id);
      if (isValidEpochTime(params.thought_id)) {
        // setGeneratedChat([]);
        fetchAndSetThoughts(params.thought_id);
      } else {
        setIsError(false);
        console.log("iserror: ", isError);
      }
    } else if (from === "new") {
      //console.log("id new: ", Id);
      // setGeneratedChat([]);
      // console.log("id new: ", params.thought_id);
      if (isValidEpochTime(params.thought_id)) {
        // setGeneratedChat([]);
        fetchAndSetThoughts(params.thought_id);
      } else {
        // throw new Error("Invalid URL");
        setIsError(false);
      }
    }
  }, []);

  useEffect(() => {
    if (lastCardRef.current) {
      lastCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [generatedChat]);

  const handlePromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPrompt(e.target.value);
  };

  const generateResponse = async () => {
    console.log("the generated cards are ", JSON.stringify(generatedChat));

    // Clear the prompt
    setPrompt("");
    const from = location.state?.from ?? "individual";
    //console.log("location update generateResponse: ", location.state.from);
    if (from === "new") {
      // console.log("generate id new: ", params.thought_id);
      try {
        const checkFileStatus = await checkFile(params.thought_id);
        // console.log("file status ", checkFileStatus);
        if (checkFileStatus.status === 401 || checkFileStatus.status === 422) {
          // document.cookie = `mentee_token_cookie=''; path=/; SameSite=None; Secure`;
          // UUIDLogin()
          // navigate("/user_login");
        }
        const fileExist = await checkFileStatus.json();
        // console.log("file exist ", fileExist.status);
        if (!fileExist.status) {
          console.log("file not exist is executed");
          // throw new Error("File not exists.");
        }
        const historyResponse = await getFileChats(params.thought_id);
        if (!historyResponse.ok) {
          console.log("history not exist is executed");

          throw new Error(`Error! status: ${historyResponse.status}`);
        }

        await fetchDataAndGenerateCard(params.thought_id);
      } catch {
        try {
          await fetchAndSetThoughts(params.thought_id);
          await fetchDataAndGenerateCard(params.thought_id);
        } catch {
          console.error("Something went wrong");
        }
      }
    } else {
      // existing thoughts
      //console.log("ID individual: ", Id);
      console.log("generate id individual: ", params.thought_id);
      if (isValidEpochTime(params.thought_id)) {
        await fetchDataAndGenerateCard(params.thought_id);
      } else {
        // throw new Error("Invalid URL");
        setIsError(false);
      }
    }
  };

  return (
    <div className="flex">
      {isError ? (
        <ErrorBoundary fallback="Data not found">
          <div className="shrink-0 w-full ">
            <div className="">
              <GeneratedChatList generatedChat={generatedChat} />
              <div ref={lastCardRef} />
            </div>
            {showRegenerateButton && (
              <button
                onClick={() =>
                  fetchGeneratedResponseUsingPrompt({
                    id: params.thought_id,
                    userInput: prompt,
                  })
                }
              >
                Regenerate
              </button>
            )}
            {/* <div className=""> */}
            <TextAreaWithButton
              prompt={prompt}
              onChange={handlePromptChange}
              onGenerateResponse={generateResponse}
              isLoading={isLoading}
            />
            {/* </div> */}
          </div>
          {showCitationSource && (
            <div className="citation-source flex-shrink-0">
              <ShowCitationSource
                style={{ flexGrow: 1 }}
                open={openRight}
                onClose={closeDrawerRight}
                fileName={fileName}
              />
            </div>
          )}
        </ErrorBoundary>
      ) : (
        <div className="flex items-center justify-center w-full ">
          <NoPage />
        </div>
      )}
    </div>
  );
};

export default Thoughts;
