import { ReactNode } from "react";
import "./Answer.module.css";
import { RelatedCaseletProps } from "../chat/RenderFollowupCaselet";

type HtmlParsedAnswer = {
  answerHtml: string | JSX.Element[];
  citations: string[];
  followupQuestions: string[];
  relatedCaselets: RelatedCaseletProps[];
};


// const citationWithLink: CitationItem[] = [...];
interface KeywordHighlightProps {
  text: string;
  keywords: string[];
}

function highlightKeywords({
  text,
  keywords,
}: KeywordHighlightProps): ReactNode[] {
  return text
    .split(" ")
    .map((word, idx) => {
      if (keywords.includes(word)) {
        return <strong key={idx}>{word}</strong>;
      } else {
        return word;
      }
    })
    .reduce((prev: ReactNode[], curr, idx) => {
      if (idx > 0) {
        prev.push(" ");
      }
      prev.push(curr);
      return prev;
    }, []);
}

export function parseAnswerToHtml(
  answer: string,
  followupQuestions: string[],
  relatedCaselets: RelatedCaseletProps[],
  // onCitationClicked: (citationFilePath: string) => void,
  citationWithLink?: any
): HtmlParsedAnswer {
  const citations: string[] = [];
  function isCitationPresent(citationToCheck: string): boolean {
    const caseSensitiveMatch = citationWithLink.find((item:any) => item.citation.split("/").pop().trim() === citationToCheck.trim());
    return caseSensitiveMatch !== undefined;
  }
  // const [citationsState, setCitationsState] = useState<string[]>()
  let parsedAnswer = answer.trim();
  const caselet_keywords = [
    "Background:",
    "Scenario:",
    "Challenge:",
    "Challenges:",
    "Options:",
    "Caselet:",
    "Outcome:",
    "Current State:",
    "Desired Transformation:",
    "Current",
    "State:",
    "Desired",
    "Transformation:",
  ];
  // Convert citationWithLink to a Map for easy lookup
  const citationMap = new Map<string, string>();
  if (citationWithLink) {
    citationWithLink.forEach((item: { citation: string; link: string }) => {
      citationMap.set(item.citation.split("/").pop() || "", item.link);
    });
  }
  const citationMapTitleWithPart = new Map<string, string>();
  if (citationWithLink) {
    citationWithLink.forEach((item: { citation: string; title: string }) => {
      citationMapTitleWithPart.set(
        item.citation.split("/").pop() || "",
        item.title
      );
    });
  }
  //console.log("parsedAnswer: ", parsedAnswer);
  let formattedText: string = parsedAnswer
    .split("\n")
    .map((line: any) => line + "\n")
    .join("");
  // console.log("formattedText: ", formattedText);
  let lastIndex = formattedText.length;
  for (let i = formattedText.length - 1; i >= 0; i--) {
    if (formattedText[i] === "]") {
      break;
    } else if (formattedText[i] === "[") {
      lastIndex = i;
      break;
    }
  }

  const truncatedAnswer = formattedText.substring(0, lastIndex);
  formattedText = truncatedAnswer;
  const paragraphs = formattedText.split(/\n/g);
  // fragments: JSX.Element[]
  const fragments:string[] = paragraphs.map((paragraph) => {
    const parts = paragraph.split(/\[([^\]]+)\]/g);
    let citationIndex: number;
    const paragraphFragments = parts.map((part, partIndex) => {
      if (partIndex % 2 === 0) {
        if (part.trim() === "") {
          return;
        }
        return (
          // <>{highlightKeywords({ text: part, keywords: caselet_keywords })}</>
          highlightKeywords({ text: part, keywords: caselet_keywords }).join(' ')
        );
      } else {
        if (citations.indexOf(part) !== -1) {
          citationIndex = citations.indexOf(part) + 1;
        } else {
          if(isCitationPresent(part)){
              citations.push(part);
              citationIndex = citations.length;
          }
          else if (part.includes("https://"))
          {
            return `[<i>${" "+part+" "}</i>](${part})`
          }
          else 
            return
          // return
        }
        //
        const link = citationMap.get(part) || "";
        // console.log("link: ",citations)
        // const title = citationMapTitleWithPart.get(part) || "";
        // console.log("title: ",title)
        //   return (
        //     //
        //     <>
        //         {link !== "" ? (
        //           // title={part.replace(/_/g, " ").replace('.txt', '')}
        //             <a href={link} className="supContainer"  target="_blank">
        //                 <sup>{citationIndex}</sup>
        //             </a>
        //         ) : (
        //           // title={part.replace(/_/g, " ").replace('.txt', '')}
        //             <a className="supContainer"  onClick={() => onCitationClicked(part)}>
        //                 <sup>{citationIndex}</sup>
        //             </a>
        //         )}
        //     </>
        // );
        // return link !== ""
        //   ? `[<sup>${citationIndex}</sup>](${link})`
        //   : `[<sup onClick={() => onCitationClicked(part)}>${citationIndex}</sup>]()`
          return link !== ""
          ? `<sup>${citationIndex}</sup>`
          :`<sup>${citationIndex}</sup>`
          // : `<a onClick={() => onCitationClicked(part)}>${citationIndex}</a>`
      }
    });
    // return <p key={index}>{paragraphFragments}</p>;
    return paragraphFragments.join('')
  });
  // console.log("citations: ", citations)
  return {
    answerHtml: fragments.join('\n'),
    citations,
    followupQuestions,
    relatedCaselets,
  };
}
