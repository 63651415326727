import { Card, CardBody, CardHeader, Typography } from "@material-tailwind/react";
const MultipleLoadingScreen = ({ count }: { count: number }) => {
    const cards = [];
    for (let i = 1; i <= count; i++) {
        cards.push(
            <div key={i} className=" space-x-4 animate-pulse  gap-4 w-full">
                <Card className="mt-6 cursor-pointer hover:cursor-pointer pb-4 card_bg_color card_text_color">
                    <CardHeader className="relative bg-slate-700">
                        <div className="mt-4 flex items-center justify-center ">
                            <Typography as="div" variant="h1" className="mb-4 h-3 w-3/12 rounded-full bg-gray-500">
                                {""}
                            </Typography>
                        </div>
                    </CardHeader>
                    <CardBody className="p-2">
                        <div className="p-4">
                            <Typography as="div" variant="paragraph" className="mb-2 h-2 w-10/12 rounded-full bg-gray-500">
                                {""}
                            </Typography>
                            <Typography as="div" variant="paragraph" className="mb-2 h-2 w-10/12 rounded-full bg-gray-500">
                                {""}
                            </Typography>
                            <Typography as="div" variant="paragraph" className="mb-2 h-2 w-6/12 rounded-full bg-gray-500">
                                {""}
                            </Typography>
                            <Typography as="div" variant="paragraph" className="mb-2 h-2 w-8/12 rounded-full bg-gray-500">
                                {""}
                            </Typography>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    
    }
    return cards;
};


export default MultipleLoadingScreen;
