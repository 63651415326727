import { Slide, toast } from "react-toastify";

export function get_mentee_data() {
  // const params = useParams()
  let userData: any = localStorage.getItem("mentee_data");
//   console.log("userData: ", userData)
  if (userData) {
    try {
      userData = JSON.parse(userData);
      return userData;
      // console.log("userData: ", userData);
    } catch (e) {
      console.error("Error parsing mentee_data from localStorage:", e);
      userData = null; // or set a default object if needed
    }
  } else {
    userData = null;
  }
}
export type SeedType = {
  id: string;
  author: string;
  avatar: string;
  date: string;
  title: string;
  description: string;
  suggestion?: string;
  fileName?: string;
  brief_summary?: string;
  elaborate_summary?: string;
};
export type OptionRefactorPayload = {
  caselet: Caselet;
  index: number;
};
export type Caselet = {
  id: string;
  title: string;
  createdTime: string;
  authorName: string;
  summary: SummaryTab;
  view: number;
  challenges: DecisionPointTab[];
  published: boolean;
  // relivenceMatrix?: RelivenceMatrixTab;
};

export type SummaryTab = {
  background: string;
  scenario: string;
  outcome: string;
  possibleCurrentStates: string[];
  desiredTransformationGoal: string[];
};

export type DecisionPointTab = {
  title: string;
  challenge: string;
  options: string[];
  videos: string[];
};

export type RelivenceMatrixTab = {};

export type HtmlParsedAnswer = {
  answerHtml: string;
  citations: string[];
};

export type QdrantObject = {
  text: string;
  citation: string;
};

export function giveErrorMessage(statusCode: number | undefined) {
  switch (statusCode) {
    case 404:
      return (
        <div className="flex card_text_color justify-center h-44 w-full font-bold p-6">
          Resource not found.
        </div>
      );
    case 500:
      return (
        <div className="flex card_text_color justify-center h-44 w-full font-bold p-6">
          Internal server error. Please try again later.
        </div>
      );
    default:
      return (
        <div className="flex card_text_color justify-center h-44 w-full font-bold p-6">
          Oops, something went wrong!{" "}
        </div>
      );
  }
}

export const truncateWords = (str: string, maxWords: number) => {
  const words = str.split(" ");
  if (words.length <= maxWords) {
    return str;
  }
  return words.slice(0, maxWords).join(" ") + " ...";
};

export function errorToast(errorMessage: string) {
  toast.error(errorMessage, {
    toastId: 1003,
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    transition: Slide,
    theme: "colored",
    style: { backgroundColor: "rgb(21 128 61))", marginTop: "8vh" },
  });
}
export function successToast(successMessage: string) {
  toast.success(successMessage, {
    toastId: 1001,
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
    theme: "colored",
    style: { backgroundColor: "rgb(67, 104, 80)", marginTop: "8vh" },
  });
}

export function isValidEpochTime(value: any): boolean {
  // Convert the value to a number if it's a string
  // console.log("value: ", value)
  if (typeof value === "string" && /^\d+$/.test(value)) {
    value = parseInt(value, 10);
  }
  // Check if the value is a number
  if (typeof value !== "number") {
    return false;
  }

  // Check if the value is within the valid UNIX timestamp range
  if (value < -8.64e12 || value > 8.64e12) {
    return false;
  }

  // Create a Date object and check if it's valid
  const date = new Date(value * 1000);
  return !isNaN(date.getTime());
}


