import React from "react";
import { LuSendHorizonal } from "react-icons/lu";

interface TextAreaWithButtonProps {
  prompt: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onGenerateResponse: () => void;
  isLoading: boolean;
}

export const TextAreaWithButton = ({
  prompt,
  onChange,
  onGenerateResponse,
  isLoading,
}: TextAreaWithButtonProps) => {
  
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (!isLoading && prompt.trim().length > 0) {
        onGenerateResponse();
      }
    }
  };

  return (
    <div className="fixed bottom-0  md:pt-0 dark:border-white/20 w-[calc(100%-6rem)] juice:w-full html_bg_color min-w-80">
      <div className="mt-3 flex items-center justify-center">
        <textarea
          className="rounded-xl resize-none border p-2 w-8/12 shadow-2xl card_bg_color card_text_color"
          id="promptInput"
          placeholder="Let's interact"
          value={prompt}
          onChange={onChange}
          onKeyDown={handleKeyDown}
        />
        <button
          onClick={onGenerateResponse}
          disabled={!prompt.trim() || isLoading}
          className="heading_text_color ml-2 "
        >
          <LuSendHorizonal />
        </button>
      </div>
      <div className="text-center	">
      <span className=" inline-block  card_text_color text-sm">
        <strong>Important:</strong>&nbsp;CH.AI is currently in Beta. There may be
        partial or sometimes inaccurate results. Please use your discretion.
      </span>
      </div>
    </div>
  );
};
