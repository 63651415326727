
 const NoPage=()=>{
    return (
        <div className="flex items-center justify-center h-screen">
            <div className="card_bg_color p-16 rounded-xl shadow-xl items-center text-center">
                <h1 className="text-4xl font-bold mb-4 card_text_color">404 - Page Not Found</h1>
                <p className="text-lg text-gray-600 mb-6 ">Oops! The page you are looking for might have been removed or doesn't exist.</p>
                {/* <Link to="/workbench" className="text-blue-500 hover:underline">Go back to Home</Link> */}
            </div>
        </div>
    );
}
export default NoPage;