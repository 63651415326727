import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "../design-system/Avatar";
import { Card, CardHeader, CardBody } from "@material-tailwind/react";
import { checkUser, getFileChats, getFiles } from "../api";
import InfiniteScroll from "react-infinite-scroll-component";
import MultipleLoadingScreen from "../design-system/ MultipleLoadingScreen";
import { loadNumberOfFiles } from "../utils/appConstant";
import { giveErrorMessage } from "../utils/common";
import useUserData from "../utils/useUserData";
type Thought = {
  filename: string;
  timestamp: string;
  query: string;
  response: string;
  citations: string[];
};

type ThoughtsHeaderProps = {
  thought: Thought;
};
const ThoughtHeader = ({ thought }: ThoughtsHeaderProps) => {
  // console.log("thought: ", thought)
  const userData = useUserData();
  const getInitials = (name: string| undefined) => {

    return name && name
      .split(" ")
      .map((n) => n[0])
      .join("");
  };
  const initials = getInitials(userData?.name);

  return (
    <div className={`grid grid-cols-3 p-1 items-center`}>
      <div className="max-w-14">
        <Avatar initials={initials} className="mr-2" />
      </div>
      <h2
        className={`justify-self-start font-title truncate col-span-2  w-4/5`}
        title={thought.query}
      >
        {thought.query}
      </h2>
    </div>
  );
};

export const ThoughtList = () => {

  const [individualThought, setIndividualThought] = useState<Thought[]>([]);

  const [hasMore, setHasMore] = useState(true);
  const [error] = useState(false);
  const navigate = useNavigate();
  const [statusCode, setStatusCode] = useState<number | undefined>();
  const [checkThoughtsFolderNotExist, setCheckThoughtsFolderNotExist] =
  useState(false);
  // const refreshToken = useRefreshToken();
  let fileIndex = 0;
  const fetchAndSetThoughts = async () => {

    try {
      // const access_token = localStorage.getItem("access_token");
      //     const flag = isTokenExpired(access_token);
      //     // console.log("flag: ", flag)
      //     if (flag) {
      //         await refreshToken();
      //     }
      const check_user_dir = await checkUser();
      // console.log("thoughts check user: ", check_user_dir.status);
      if (check_user_dir.status === 401 || check_user_dir.status === 422) {
        // document.cookie = `mentee_token_cookie=''; path=/; SameSite=None; Secure`;
        // localStorage.removeItem("mentee_data");
        // navigate("/user_login");
      } else if (!check_user_dir.ok) {
        throw new Error(`Error! status: ${check_user_dir.status}`);
      }
      const checkUserDirExist = await check_user_dir.json();
    //   console.log("check user exist: ", checkUserDirExist.exists);
      if (!checkUserDirExist.exists) {
        // setUserNotExist(true);
        return;
      } else {
        const thoughtAllFiles = await getFiles();
        // console.log("thoughtAllFiles:", thoughtAllFiles.status)
        if (thoughtAllFiles.status === 401 || thoughtAllFiles.status === 422) {
          // document.cookie = `mentee_token_cookie=''; path=/; SameSite=None; Secure`;
          // navigate("/user_login");
        }
        
        if (!thoughtAllFiles.ok) {
            setStatusCode(thoughtAllFiles.status);
          throw new Error(`Error! status: ${thoughtAllFiles.status}`);
        }
        const files = await thoughtAllFiles.json();
        if (files.error) {
            setCheckThoughtsFolderNotExist(true);
            return;
          }
        // console.log("before files: ", files);
        files.sort((a: any, b: any) => {
          const timestampA = Number(a.slice(0, -4)); // Remove '.txt' and convert to number
          const timestampB = Number(b.slice(0, -4)); // Remove '.txt' and convert to number
          return timestampB - timestampA; // Sort in descending order (most recent first)
        });
        // console.log("After files: ", files)
        // setCaselet(fetchedCaselets);
        // setTimeout(async()=>{

        let tempIndividualThought: Thought[] = [];
        for (
          let i = 0;
          i < Math.min(files.length, loadNumberOfFiles) &&
          fileIndex < files.length;
          i++
        ) {
          const historyResponse = await getFileChats(
            files[fileIndex].substring(0, files[fileIndex].lastIndexOf("."))
          );
          // if (!historyResponse.ok) {
          //   throw new Error(`Error! status: ${historyResponse.status}`);
          // }
          if (!historyResponse.ok) {
            // throw new Error(`Error! status: ${historyResponse.status}`);
            // i++;
            fileIndex++
            continue;
        }
          const data = await historyResponse.json();
          const lastObject = data[data.length - 1];
          lastObject["filename"] = files[fileIndex].substring(
            0,
            files[fileIndex].lastIndexOf(".")
          );
          tempIndividualThought.push(lastObject);
          fileIndex++;
        }
        setIndividualThought((prevThoughts) => [
          ...prevThoughts,
          ...tempIndividualThought,
        ]);
        if (files.length === fileIndex) {
          setHasMore(false);
          return;
        }
    // }, 1000)
      }
     
    } catch (error) {
        // setError(true);
      console.error(`Failed to fetch thought files `, error);
    } 
  };

  const fetchMoreThoughts = useCallback(() => {
    if (hasMore) {
      fetchAndSetThoughts();
      // setCurrentPage(prevPage => prevPage +  1);
    }
  }, [hasMore]);

  useEffect(() => {
    fetchAndSetThoughts();
  }, []);

  // if (isLoading) {
  //     return (
  //         <div className="p-4">
  //             <LoadingDots />
  //         </div>
  //     );
  // }
  if (checkThoughtsFolderNotExist) {
    return (
      <div className="h-44 card_text_color">Resource not found.</div>
    );
  }
  if (error) {
    return giveErrorMessage(statusCode);
}
  return (
    <div className="flex-grow pb-8 relative">
      <InfiniteScroll
        dataLength={individualThought.length}
        next={fetchMoreThoughts}
        hasMore={hasMore}
        loader={
          <div className="p-4">
            {/* <LoadingScreen></LoadingScreen> */}
            <MultipleLoadingScreen count={3} />
          </div>
        }
      >
        {individualThought.map((item: Thought, index) => {
          item.response = item.response.replace(/\[[^\]]*\]/g, "");
          const openSpecificThoughtClick = (item: any) => {
            navigate(`${item.filename}`, {
              state: { from: "individual" },
            });
          };

          return (
            <Card
              onClick={() => openSpecificThoughtClick(item)}
              key={index}
              className="mt-6 hover:cursor-pointer  pb-4 card_bg_color card_text_color "
            >
              <CardHeader className="relative card-header-style ">
                <ThoughtHeader thought={item} />
              </CardHeader>
              <CardBody className="p-2 overflow-auto  max-h-[300px] scroll-style">
                {item.response.split("\n").map((line: any, index: any) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </CardBody>
            </Card>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default ThoughtList;
