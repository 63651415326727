import React from "react";
interface ErrorBoundaryProps {
    fallback: React.ReactNode;
    children: React.ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
    state = { hasError: false };

    static getDerivedStateFromError(error: Error) {
        return { hasError: true ,  error:error};
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        console.log(error, info);
    }


    render() {
            


        if (this.state.hasError) {
            return (
               <div className="card-component bg-white mt-4 text-black p-4 rounded-lg transition-transform w-full sm:w-8/10 relative cursor-pointer shadow-lg">
                        <h2 className="font-title ">{"Error: "}</h2>
                        <div className="card-content min-h-fit">{this.props.fallback}</div>
                    </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
